import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_core/guard/auth-guard.guard';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { OrganizationGuard } from './_core/guard/organization.guard';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';

const routes: Routes = [
  {
    path: 'login',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    component: AuthLayoutComponent,
    path: '',
    canActivateChild: [AuthGuard],
    children: [
      {
        component: SideMenuComponent,
        path: '',
        canActivate: [OrganizationGuard],
        children: [
          {
            path: 'apartment',
            loadChildren: () =>
              import('./apartment/apartment.module').then(
                (m) => m.ApartmentModule
              ),
          },
          {
            path: 'dashboard',
            loadChildren: () =>
              import('./dashboard/dashboard.module').then(
                (m) => m.DashboardModule
              ),
          },
          {
            path: 'dweller',
            loadChildren: () =>
              import('./dweller/dweller.module').then((m) => m.DwellerModule),
          },
          {
            path: 'works',
            loadChildren: () =>
              import('./work/work.module').then((m) => m.WorkModule),
          },
          {
            path: 'contractors',
            loadChildren: () =>
              import('./contractor/contractor.module').then(
                (m) => m.ContractorModule
              ),
          },
          {
            path: 'accounts',
            loadChildren: () =>
              import('./accounts/accounts.module').then(
                (m) => m.AccountsModule
              ),
          },
          {
            path: 'invoices',
            loadChildren: () =>
              import('./invoice/invoice.module').then((m) => m.InvoiceModule),
          },

          { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
        ],
      },
      {
        path: 'organization',
        loadChildren: () =>
          import('./organization/organization.module').then(
            (m) => m.OrganizationModule
          ),
      },

      // {
      //   path: 'assets',
      //   loadChildren: () =>
      //     import('./assets/assets.module').then((m) => m.AssetsModule),
      // },

      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    ],
  },

  // {
  //   path: '**',
  //   loadChildren: () =>
  //     import('./page-not-found/page-not-found.module').then(
  //       (m) => m.PageNotFoundModule
  //     ),
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
