import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { AuthService } from 'src/app/_services/auth.service';
import { OrganizationService } from 'src/app/_services/organization.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService,
    private organizationService: OrganizationService
  ) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return this._getHttpResponse(this._addHeaders(req), next).pipe(
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case 400:
            this._handle400Error(error);
            return throwError(() => error);
          case 401:
            return this._handle401Error(req, next);
          default:
            return throwError(() => error);
        }
      })
    );
  }

  private async _handle400Error(error: any) {
    alert(error.error);
  }

  private _handle401Error(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> {
    this.authService.logout().subscribe(() => this.router.navigate(['/login']));
    return of(null);
  }

  private _getHttpResponse(request: HttpRequest<any>, next: HttpHandler) {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          event = event.clone({ body: event.body });
        }
        return event;
      })
    );
  }

  private _addHeaders(request: HttpRequest<any>): HttpRequest<any> {
    const jwtToken = this.authService.getJwtToken();
    const orgId = this.organizationService.selectedOrgId();
    request = request.clone({
      setHeaders: {
        authorization: `Bearer ${jwtToken}`,
        companyId: orgId || '',
      },
    });

    if (!request.headers.has('Content-Type')) {
      request = request.clone({
        setHeaders: {
          'content-type': 'application/json',
        },
      });
    }

    request = request.clone({
      headers: request.headers.set('Accept', 'application/json'),
    });

    return request;
  }
}
