import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { OrganizationService } from 'src/app/_services/organization.service';

@Injectable({
  providedIn: 'root',
})
export class OrganizationGuard implements CanActivateChild, CanActivate {
  constructor(
    private organizationService: OrganizationService,
    private router: Router
  ) {}

  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.organizationService
      .isOrganizationSelected()
      .then((isSelected) => {
        if (isSelected) {
          return true;
        } else {
          this.router.navigate(['/organization']);
          return false;
        }
      });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.organizationService
      .isOrganizationSelected()
      .then((isSelected) => {
        if (isSelected) {
          return true;
        } else {
          this.router.navigate(['/organization']);
          return false;
        }
      });
  }
}
