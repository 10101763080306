import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import format from 'date-fns/format';
import DateUtils from '../_core/date-utils';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private _baseApi = '';

  constructor(
    private http: HttpClient,
    @Inject('API_ENDPOINT') private apiEndpoint: string
  ) {
    this._baseApi = this.apiEndpoint;
  }

  createApartment(apartment: any) {
    return this.http.post<number>(`${this._baseApi}/Apartment`, apartment);
  }

  getApartments(buildingId: string) {
    return this.http.get<any[]>(`${this._baseApi}/Apartment`, {
      params: new HttpParams().set('buildingId', buildingId),
    });
  }

  getCurrentEmployee() {
    return this.http.get<any>(`${this._baseApi}/Employee/Current`);
  }

  updateApartment(id: number, apartment: any) {
    return this.http.patch<void>(`${this._baseApi}/Apartment/${id}`, apartment);
  }

  getOrganizations() {
    return this.http.get<any[]>(`${this._baseApi}/Organization`);
  }

  createOrganization(org: any) {
    return this.http.post<any>(`${this._baseApi}/Organization`, org);
  }

  getContractors() {
    return this.http.get<any[]>(`${this._baseApi}/Contractor`);
  }

  createContractor(contractor: any) {
    return this.http.post<any>(`${this._baseApi}/Contractor`, contractor);
  }

  updateContractor(contractorId: number, contractor: any) {
    return this.http.patch<void>(
      `${this._baseApi}/Contractor/${contractorId}`,
      contractor
    );
  }

  importPayments(fileBase64: string) {
    return this.http.post<void>(`${this._baseApi}/ImportPayments/PrivatBank`, {
      fileBase64: fileBase64,
    });
  }

  getCompanyAccounts(periodFrom: Date, periodTo: Date) {
    return this.http.get<any[]>(`${this._baseApi}/Account`, {
      params: new HttpParams()
        .set('periodFrom', DateUtils.format(periodFrom))
        .set('periodTo', DateUtils.format(periodTo)),
    });
  }

  generateInvoices(month: number, year: number) {
    return this.http.post<void>(`${this._baseApi}/Invoice`, {
      month,
      year,
    });
  }

  getInvoices(month: number, year: number) {
    return this.http.get<any[]>(`${this._baseApi}/Invoice`, {
      params: new HttpParams().set('month', month).set('year', year),
    });
  }
}
