import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { MaterialModule } from './_core/material.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthGuard } from './_core/guard/auth-guard.guard';
import { AuthService } from './_services/auth.service';
import { TokenInterceptor } from './_core/interceptor/token.interceptor';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import '@angular/common/locales/global/uk';

@NgModule({
  declarations: [AppComponent, SideMenuComponent, AuthLayoutComponent],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
  ],
  providers: [
    { provide: 'API_ENDPOINT', useValue: environment.apiEndpoint },
    AuthGuard,
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'uk' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
