import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { ApiService } from '../_services/api.service';
import { OrganizationService } from '../_services/organization.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent {
  organizations: any[] = [];
  selectedOrg: any;
  loading = false;

  constructor(
    private authService: AuthService,
    private router: Router,
    private apiService: ApiService,
    private organizationService: OrganizationService
  ) {}

  ngOnInit() {
    this.apiService.getOrganizations().subscribe((res) => {
      this.organizations = res;
      const selectedOrgId = this.organizationService.selectedOrgId();
      if (selectedOrgId) {
        this.selectedOrg = this.organizations.find(
          (o) => o.uuid == selectedOrgId
        );
      }
    });
  }

  selectOrganization(org: any) {
    this.loading = true;
    this.organizationService.seelectOrganization(org.uuid);
    location.reload();
  }

  logOut() {
    this.authService.logout().subscribe(() => this.router.navigate(['/login']));
  }
}
