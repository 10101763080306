<div class="app-container">
  <mat-toolbar color="primary" class="app-header">
    <button class="me-3" mat-icon-button (click)="snav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <span class="me-3">SmartDim</span>
    <button
      mat-raised-button
      *ngIf="selectedOrg"
      [matMenuTriggerFor]="orgsMenu"
    >
      {{ selectedOrg.name }}
    </button>
    <span class="bg-loader" *ngIf="loading"></span>
    <span class="flex-spacer"></span>
    <button
      matBadge="2"
      matBadgeColor="warn"
      type="button"
      mat-icon-button
      class="me-3"
    >
      <mat-icon>notifications</mat-icon>
    </button>
    <button type="button" mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>account_circle</mat-icon>
    </button>
  </mat-toolbar>

  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav #snav mode="side" opened class="sidenav">
      <mat-nav-list>
        <mat-list-item routerLink="dashboard" routerLinkActive="active">
          <mat-icon matListItemIcon>dashboard</mat-icon>
          <div mat-line>Панель</div>
        </mat-list-item>
        <mat-list-item routerLinkActive="active">
          <mat-icon matListItemIcon>date_range</mat-icon>
          <div mat-line>Будинки</div>
        </mat-list-item>
        <mat-list-item routerLink="apartment" routerLinkActive="active">
          <mat-icon matListItemIcon>date_range</mat-icon>
          <div mat-line>Квартири</div>
        </mat-list-item>
        <!-- <mat-list-item routerLink="clients" routerLinkActive="active">
          <mat-icon matListItemIcon>people</mat-icon>
          <div mat-line>Clients</div>
        </mat-list-item>
        <mat-list-item routerLink="jobs" routerLinkActive="active">
          <mat-icon matListItemIcon>miscellaneous_services</mat-icon>
          <div mat-line>Jobs</div>
        </mat-list-item> -->
        <mat-list-item routerLink="dweller" routerLinkActive="active">
          <mat-icon matListItemIcon>supervised_user_circle</mat-icon>
          <div mat-line>Мешканці</div>
        </mat-list-item>
        <mat-list-item routerLink="assets" routerLinkActive="active">
          <mat-icon matListItemIcon>shopping_bag</mat-icon>
          <div mat-line>Інвентар</div>
        </mat-list-item>
        <mat-list-item routerLink="employees" routerLinkActive="active">
          <mat-icon matListItemIcon>shopping_bag</mat-icon>
          <div mat-line>Працівники</div>
        </mat-list-item>
        <mat-list-item routerLinkActive="active">
          <mat-icon matListItemIcon>shopping_bag</mat-icon>
          <div mat-line>Договори</div>
        </mat-list-item>
        <mat-list-item routerLinkActive="active">
          <mat-icon matListItemIcon>shopping_bag</mat-icon>
          <div mat-line>Інвентар</div>
        </mat-list-item>
        <mat-list-item routerLink="accounts" routerLinkActive="active">
          <mat-icon matListItemIcon>shopping_bag</mat-icon>
          <div mat-line>Рахунки</div>
        </mat-list-item>
        <mat-list-item routerLink="works" routerLinkActive="active">
          <mat-icon matListItemIcon>shopping_bag</mat-icon>
          <div mat-line>Роботи</div>
        </mat-list-item>
        <mat-list-item routerLink="contractors" routerLinkActive="active">
          <mat-icon matListItemIcon>shopping_bag</mat-icon>
          <div mat-line>Підрядники</div>
        </mat-list-item>
        <mat-list-item routerLink="invoices" routerLinkActive="active">
          <mat-icon matListItemIcon>shopping_bag</mat-icon>
          <div mat-line>Квитанції</div>
        </mat-list-item>
        <mat-list-item routerLinkActive="active">
          <mat-icon matListItemIcon>shopping_bag</mat-icon>
          <div mat-line>Витрати</div>
        </mat-list-item>
        <!-- <mat-list-item routerLink="language" routerLinkActive="active">
          <mat-icon matListItemIcon>shopping_bag</mat-icon>
          <div mat-line>Language</div>
        </mat-list-item> -->
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<mat-menu #menu="matMenu">
  <button mat-menu-item>
    <mat-icon>person</mat-icon>
    <span>Personal info</span>
  </button>
  <button mat-menu-item>
    <mat-icon>settings</mat-icon>
    <span>Settings</span>
  </button>
  <button mat-menu-item (click)="logOut()">
    <mat-icon>logout</mat-icon>
    <span>Logout</span>
  </button>
</mat-menu>

<mat-menu #orgsMenu="matMenu">
  <button
    mat-menu-item
    *ngFor="let org of organizations"
    [disabled]="selectedOrg && selectedOrg.uuid == org.uuid"
    (click)="selectOrganization(org)"
  >
    <span>{{ org.name }}</span>
  </button>
</mat-menu>
