import { differenceInHours, format, parseISO } from 'date-fns';
import { uk } from 'date-fns/locale';

export default class DateUtils {
  private static _dateTimeWithSecondsFormat = "yyyy-MM-dd'T'HH:mm:ss";
  private static _dateTimeFormat = "yyyy-MM-dd'T'HH:mm";

  private static _displayWithSecondsFormat = 'dd.MM.yyyy HH:mm:ss';
  private static _displayFormat = 'dd.MM.yyyy HH:mm';

  static display(date?: any, withSeconds = true): string {
    if (!date) {
      date = new Date();
    }

    if (typeof date == 'string') {
      date = parseISO(date);
    }

    const _format = withSeconds
      ? this._displayWithSecondsFormat
      : this._displayFormat;
    return format(date, _format);
  }

  static format(date?: any, withSeconds = true): string {
    if (!date) {
      date = new Date();
    }

    if (typeof date == 'string') {
      date = parseISO(date);
    }

    const _format = withSeconds
      ? this._dateTimeWithSecondsFormat
      : this._dateTimeFormat;
    return format(date, _format, { locale: uk });
  }

  static formatCustom(date: any, customFormat: string): string {
    if (typeof date == 'string') {
      date = parseISO(date);
    }

    return format(date, customFormat, { locale: uk });
  }

  static differenceInHours(start: any, end: any): number {
    if (typeof start == 'string') {
      start = parseISO(start);
    }

    if (typeof end == 'string') {
      end = parseISO(end);
    }

    return differenceInHours(end, start);
  }
}
