import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  constructor() {}

  isOrganizationSelected() {
    const orgId = localStorage.getItem('__dim__organization_id');
    return Promise.resolve(!!orgId);
  }

  seelectOrganization(orgId: string) {
    localStorage.setItem('__dim__organization_id', orgId);
  }

  selectedOrgId() {
    return localStorage.getItem('__dim__organization_id');
  }
}
